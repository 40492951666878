import React from 'react'
// import { Link, graphql } from 'gatsby'
// import styled from 'styled-components'
// import SEO from '../components/seo'
// import { Layout } from '../components/Layout'
// import { colors } from '../constants'
// import { fontsName } from '../fonts/fontsName'

// const ProjectLink = styled(Link)`
//   color: ${colors.textUnselected};
//   display: block;
//   font-size: 36px;
//   font-family: ${fontsName.KnockoutHTF50Welterweight};
//   letter-spacing: 3px;
//   margin: 45px 0;
//   text-decoration: none;
//   text-transform: uppercase;
//   height: 40px;
//   display: flex;
//   align-items: center;
//   transition: all 0.2s;
//   :hover {
//     color: #fff;
//     font-size: 40px;
//     /* font-family: ${fontsName.KnockoutHTF93UltmtHeviwt}; */
//   }
// `

// const ProjectLinkExternal = styled.a`
//   color: ${colors.textUnselected};
//   display: block;
//   font-size: 36px;
//   font-family: ${fontsName.KnockoutHTF50Welterweight};
//   letter-spacing: 3px;
//   margin: 45px 0;
//   text-decoration: none;
//   text-transform: uppercase;
//   height: 40px;
//   display: flex;
//   align-items: center;
//   transition: all 0.2s;
//   :hover {
//     color: #fff;
//     font-size: 40px;
//   }
// `

// const Number = styled.span`
//   color: #fff;
//   /* font-size: 16px; */
//   font-size: 0.5em;
//   font-family: ${fontsName.KnockoutHTF54Sumo};
//   margin-right: 20px;
//   opacity: 0.3;
// `

// const Wrapper = styled.div`
//   max-width: 500px;
//   margin: 0 auto;
// `

// function filterProject({ node }) {
//   return !node.frontmatter.hidden
// }

// function sortProject(a, b) {
//   return a.node.fields.index - b.node.fields.index
// }

// const BlogIndex = ({ data }) => {
//   const { edges: projects } = data.allMdx

//   return (
//     <Layout>
//       <SEO title="Projets" />
//       <Wrapper>
//         <ul>
//           {projects.filter(filterProject).sort(sortProject).map(({ node: project }, index) => {
//             const Link = project.frontmatter.link
//               ? ProjectLinkExternal
//               : ProjectLink

//             const linkProps = project.frontmatter.link
//               ? { href: project.frontmatter.link, target: '_blank' }
//               : { to: project.fields.slug }

//             return (
//               <li key={project.id}>
//                 {
//                   // @ts-ignore
//                   <Link {...linkProps}>
//                     <h2>
//                       <Number>0{index + 1}</Number>
//                       {project.frontmatter.title}
//                     </h2>
//                   </Link>
//                 }
//               </li>
//             )
//           })}
//         </ul>
//       </Wrapper>
//     </Layout>
//   )
// }

// export const pageQuery = graphql`
//   query blogIndex {
//     allMdx {
//       edges {
//         node {
//           id
//           excerpt
//           frontmatter {
//             title
//             hidden
//             link
//           }
//           fields {
//             slug
//             index
//           }
//         }
//       }
//     }
//   }
// `

// export default BlogIndex

import About from './about'

export default About;
